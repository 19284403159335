import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useState } from 'react';

const InputPassword = forwardRef((props: TextFieldProps, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      sx={{
        '& .MuiInputBase-root': {
          background: '#f4f4f4',
          borderRadius: '12px',
        },
      }}
      InputProps={{
        inputRef: ref,
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={() => setShowPassword((prev) => !prev)}>
              {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          adornedEnd: 'pr-2',
        },
      }}
      {...props}
    />
  );
});

export default InputPassword;
