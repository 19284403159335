export enum TransactionStatus {
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  UNRESOLVED = 'UNRESOLVED',
  RESOLVED = 'RESOLVED',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}
