import { Divider, Grid, Paper } from '@mui/material';
import { DoughnutChart, PaymentTransactionList, WithdrawTransactionList } from '.';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useSearch } from 'hooks';
import { adminService } from 'services';
import { useQuery } from '@tanstack/react-query';

const Home = () => {
  const { control, clearErrors, watch } = useForm({ mode: 'onChange' });
  const { fromDate, toDate } = watch();

  const [dataSearch, onSearchChange] = useSearch();

  const { data } = useQuery(['adminService.getMerchantStatisticDashboard', dataSearch], () =>
    adminService.getMerchantStatisticDashboard(dataSearch),
  );

  return (
    <>
      <div className='flex justify-end gap-x-4 mb-4'>
        <Controller
          name='fromDate'
          defaultValue={DateTime.now().minus({ months: 1 })}
          control={control}
          rules={{
            validate: {
              isValid: (value: DateTime) => value && value.isValid,
              isAfter: (value) =>
                DateTime.fromISO(value) < DateTime.fromISO(toDate) || 'From date must be before to date',
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <DesktopDatePicker
              value={value}
              onChange={(value: any) => {
                onChange(value);
                if (DateTime.fromISO(value) < DateTime.fromISO(toDate)) {
                  clearErrors(['fromDate', 'toDate']);
                  onSearchChange({ fromDate: value.toISO(), toDate: toDate.toISO() });
                }
              }}
              slotProps={{
                textField: { error: invalid, helperText: error?.message },
              }}
              label='From Date'
            />
          )}
        />
        <Controller
          name='toDate'
          defaultValue={DateTime.now()}
          control={control}
          rules={{
            validate: {
              isValid: (value: DateTime) => value && value.isValid,
              isAfter: (value) =>
                DateTime.fromISO(value) > DateTime.fromISO(fromDate) || 'To date must be after from date',
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <DesktopDatePicker
              value={value}
              onChange={(value: any) => {
                onChange(value);
                if (DateTime.fromISO(value) > DateTime.fromISO(fromDate)) {
                  clearErrors(['fromDate', 'toDate']);
                  onSearchChange({ fromDate: fromDate.toISO(), toDate: value.toISO() });
                }
              }}
              slotProps={{
                textField: { error: invalid, helperText: error?.message },
              }}
              label='To Date'
            />
          )}
        />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={3}>
          <Paper className='p-5 '>
            <div className='text-sm font-semibold text-primary-main'>Merchant's Balance</div>
            <div className='text-2xl font-semibold my-2'>${data?.balance}</div>
            <div className='flex items-center gap-x-1'>
              <div className='text-[#57AE38] text-sm font-semibold'>+ 2.0%</div>
              <div className='text-xs'>than last month</div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper className='p-5 '>
            <div className='text-sm font-semibold text-primary-main'>Total Fees</div>
            <div className='text-2xl font-semibold my-2'>${data?.totalFee}</div>
            <div className='flex items-center gap-x-1'>
              <div className='text-[#57AE38] text-sm font-semibold'>+ 2.0%</div>
              <div className='text-xs'>than last month</div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper className='p-5 '>
            <div className='text-sm font-semibold text-primary-main'>Total In</div>
            <div className='text-2xl font-semibold my-2'>${data?.totalIn}</div>
            <div className='flex items-center gap-x-1'>
              <div className='text-[#57AE38] text-sm font-semibold'>+ 2.0%</div>
              <div className='text-xs'>than last month</div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper className='p-5 '>
            <div className='text-sm font-semibold text-primary-main'>Total Out</div>
            <div className='text-2xl font-semibold my-2'>${data?.totalOut}</div>
            <div className='flex items-center gap-x-1'>
              <div className='text-[#E9422C] text-sm font-semibold'>- 2.0%</div>
              <div className='text-xs'>than last month</div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Paper className='p-5 my-6 flex'>
        <Grid container justifyContent='space-between' spacing={4}>
          <Grid item xs={12} lg={5.5}>
            <PaymentTransactionList search={dataSearch} />
          </Grid>
          <Grid item md={1} container justifyContent='center' alignItems='center'>
            <Divider orientation='vertical' style={{ height: '100%' }} className='border border-[#C7CBCF]' />
          </Grid>
          <Grid item xs={12} lg={5.5}>
            <WithdrawTransactionList search={dataSearch} />
          </Grid>
        </Grid>
      </Paper>
      <Paper className='p-5'>
        <div className='text-xl font-semibold'>Balance Overview</div>
        <div className='my-10'>
          <DoughnutChart dataSearch={dataSearch} />
        </div>
      </Paper>
    </>
  );
};

export default Home;
