import { camelizeKeys } from 'humps';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { API_URL } from 'env';
import { openAlert } from 'reducers/notificationSlice';
import { signOut } from 'reducers/profileSlice';
import { store } from 'reducers/store';
import queryString from 'query-string';

const beforeRequest = (config: InternalAxiosRequestConfig) => {
  const { isLoggedIn, accessToken }: ProfileRecordType = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers as any, {
      Authorization: `Bearer ${accessToken}`,
    });
  }
  try {
    if (config.data instanceof FormData) {
      Object.assign(config.headers as any, { 'Content-Type': 'multipart/form-data' });
    }
  } catch {}
  return config;
};

const onError = async (error: AxiosError) => {
  const { response } = error;
  if (response) {
    const { status } = response;
    if (status === 401) {
      store.dispatch(signOut({}));
    }

    const data = response?.data as any;
    const { message } = data.errors[0] || 'Something went wrong';
    store.dispatch(openAlert({ message, variant: 'error' }));
  }
  return Promise.reject(error);
};

const client = axios.create({ baseURL: API_URL });
client.defaults.paramsSerializer = (params) =>
  queryString.stringify(
    Object.keys(params)
      .filter((key) => String(params[key]).trim())
      .reduce((trim, key) => ({ ...trim, [key]: params[key] }), {}),
  );

client.interceptors.request.use(beforeRequest);
client.interceptors.response.use((response) => {
  const { success = 1, data, errors } = response.data;
  if (success) {
    if (['POST', 'PUT', 'DELETE'].includes(response.config.method?.toUpperCase()!)) {
      store.dispatch(openAlert({ message: 'Success' }));
    }
    return data;
  } else {
    const message = errors?.message ?? 'Something went wrong';
    store.dispatch(openAlert({ message, variant: 'error' }));

    return Promise.reject(message);
  }
}, onError);

client.defaults.transformResponse = [...(axios.defaults.transformResponse as []), (data) => camelizeKeys(data)];

export { client };
