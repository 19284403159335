import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseButton } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { queryClient } from 'services';
import { adminService } from 'services';

const PopupAddMerchant = ({ onClose }: PopupController) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: addMerchant, isLoading } = useMutation(adminService.addMerchant, {
    onSuccess: () => {
      queryClient.invalidateQueries(['adminService.fetchMerchants']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addMerchant(values as AddMerchantType);
    })();
  };

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Add Merchant</DialogTitle>

      <DialogContent className='mt-6'>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='email'
              defaultValue=''
              control={control}
              rules={{ required: 'Email is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Email</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='phoneNumber'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Phone Number</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              rules={{ required: 'Name is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Name</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='description'
              defaultValue=''
              control={control}
              rules={{ required: 'Description is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Description</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='url'
              defaultValue=''
              control={control}
              rules={{ required: 'Url is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Url</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='code'
              defaultValue=''
              control={control}
              rules={{ required: 'Code is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Code</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='address'
              defaultValue=''
              control={control}
              rules={{ required: 'Address is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Address</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='text' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' color='primary' loading={isLoading} onClick={handleClickSubmit}>
          Create
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupAddMerchant;
