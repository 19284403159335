import { LoadingButton } from '@mui/lab';
import { Container, FormControl, IconButton, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { signIn } from 'reducers/profileSlice';
import { authService } from 'services';
import { isEmail } from 'utils/common';

const Login = () => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: (data) => {
      dispatch(signIn(data));
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login(values as LoginBody);
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <div className='flex flex-col h-screen'>
      <div className='flex px-4 md:px-20 items-center justify-between h-[76px]'>
        <img src={require('assets/images/Image.Logo.png')} className='h-6' />
        <div className='flex items-center md:gap-x-4'>
          <IconButton href='https://www.facebook.com/' target='_blank' rel='noreferrer'>
            <img src={require('assets/icons/Icon.Facebook.png')} />
          </IconButton>
          <IconButton href='' target='_blank' rel='noreferrer'>
            <img src={require('assets/icons/Icon.Twitter.png')} />
          </IconButton>
          <IconButton href='' target='_blank' rel='noreferrer'>
            <img src={require('assets/icons/Icon.Telegram.png')} />
          </IconButton>
          <IconButton href='' target='_blank' rel='noreferrer'>
            <img src={require('assets/icons/Icon.Global.png')} />
          </IconButton>
        </div>
      </div>
      <div className='flex flex-1 flex-col gap-y-4 md:flex-row items-center'>
        <div className='bg-[#F2FBFF] md:w-[581px] md:h-full flex items-center justify-center'>
          <img src={require('assets/images/Cover.Login.png')} />
        </div>
        <Container maxWidth='sm'>
          <div className='text-xl font-semibold'>Welcome to s6k Commerce! 👋🏻</div>
          <div className='text-sm text-[#171C1C] mt-1 mb-6'>Sign-in to your account to continue</div>
          <div className='flex flex-col gap-y-6'>
            <Controller
              name='email'
              defaultValue=''
              control={control}
              rules={{
                required: 'Email is required',
                validate: (email) => isEmail(email) || 'Email is invalid',
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] text-sm mb-1'>Email</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
            <Controller
              name='password'
              defaultValue=''
              control={control}
              rules={{
                required: 'Password is required',
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] text-sm mb-1'>Password</label>
                  <InputPassword {...field} error={invalid} helperText={error?.message} onKeyDown={handleKeyDown} />
                </FormControl>
              )}
            />

            <LoadingButton
              size='large'
              variant='contained'
              color='primary'
              disableElevation={false}
              onClick={handleClickSubmit}
              loading={isLoading}
            >
              Login
            </LoadingButton>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Login;
