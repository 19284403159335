import { CloudQueue } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useWindowDimensions } from 'hooks';
import { Doughnut } from 'react-chartjs-2';
import { adminService } from 'services';
import { formatBalance } from 'utils/common';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ dataSearch }: { dataSearch: any }) => {
  const { isMobile } = useWindowDimensions();

  const { data: merchantsBalance } = useQuery(['adminService.getMerchantBalanceStatistic', dataSearch], () =>
    adminService.getMerchantBalanceStatistic(dataSearch),
  );

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = 'bold 24px Inter';
      ctx.fillStyle = '#111315';
      ctx.fillText(
        `$${merchantsBalance?.total}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y - 10,
      );

      ctx.font = '14px Inter';
      ctx.fillStyle = '#111315';
      ctx.fillText('Total Balance', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
      ctx.save();
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    centerText: {
      display: true,
    },
    plugins: {
      legend: {
        position: isMobile ? ('bottom' as const) : ('right' as const),
        labels: {
          usePointStyle: true,
          padding: 24,
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 15,
            color: '#111315',
            family: 'Inter',
            weight: '600',
          },
          generateLabels(chart: any) {
            return chart.data.labels.map((label: any, i: any) => ({
              text: `${label}      $${formatBalance(chart.data.datasets[0].data[i])}`,
              fillStyle: chart.data.datasets[0].backgroundColor[i],
            }));
          },
        },
      },
    },
  };

  const data = {
    labels: merchantsBalance?.data.map((item) => item.code),
    datasets: [
      {
        label: 'Balance',
        data: merchantsBalance?.data.map((item) => parseFloat(item.availableBalance)),
        backgroundColor: ['#36C5F0', '#2EB67D', '#FFB100', '#E01E5A'],
        borderColor: ['#36C5F0', '#2EB67D', '#FFB100', '#E01E5A'],
        borderWidth: 1,
      },
    ],
  };

  return merchantsBalance?.data.length! > 0 ? (
    <div className='max-w-screen-lg'>
      <Doughnut data={data} options={options} height={isMobile ? 400 : 240} plugins={[textCenter]} />
    </div>
  ) : (
    <div className='text-center'>
      <CloudQueue fontSize='large' />
      <div className='text-sm font-medium'>No Data</div>
    </div>
  );
};

export default DoughnutChart;
