import { AppHeader } from 'containers';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { authRoute, privateRoute } from 'routes';

const PrivateLayout = () => {
  const { isReady } = useSelector(systemSelector);
  const { isLoggedIn } = useSelector(profileSelector);

  return (
    <div className='bg-paper-gray'>
      <AppHeader />
      <main className='md:pl-[92px] px-4 md:pr-8 py-16'>
        {isReady ? (
          <Routes>
            {Object.values(privateRoute)
              .filter(({ requiredLogin }: any) => !requiredLogin || isLoggedIn)
              .map(({ path, component: Element }) => (
                <Route key={path} path={path} element={<Element />} />
              ))}
            <Route path='*' element={<Navigate to={authRoute.login.url} />} />
          </Routes>
        ) : (
          <></>
        )}
      </main>
    </div>
  );
};

export default PrivateLayout;
