import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { RejectTransactionType } from 'models/Transaction';
import { Controller, useForm } from 'react-hook-form';
import { queryClient, adminService } from 'services';

type PopupProps = PopupController & {
  id: string;
};

const PopupReject = ({ id, onClose }: PopupProps) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate, isLoading } = useMutation(adminService.rejectTransaction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['adminService.fetchTransactions']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate({ id, ...values } as RejectTransactionType);
    })();
  };

  return (
    <>
      <DialogTitle>REJECT</DialogTitle>

      <DialogContent>
        <Controller
          name='extraReason'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl fullWidth>
              <label className='text-[#6F767E] font-semibold text-sm mb-1'>Extra Reason</label>
              <TextField {...field} error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='text' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupReject;
