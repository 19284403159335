import { Home } from 'views/Home';
import { MerchantList } from 'views/Merchant';
import { NetworkList } from 'views/Network';
import { TransactionList } from 'views/Transaction';

const privateRoute = {
  home: {
    path: '/home',
    url: '/home',
    component: Home,
    requiredLogin: true,
  },
  merchant: {
    path: '/merchant',
    url: '/merchant',
    component: MerchantList,
    requiredLogin: true,
  },
  network: {
    path: '/network',
    url: '/network',
    component: NetworkList,
    requiredLogin: true,
  },
  transaction: {
    path: '/transaction',
    url: '/transaction',
    component: TransactionList,
    requiredLogin: true,
  },
};

export default privateRoute;
