import { List, ListItemButton, ListItemIcon } from '@mui/material';
import { styled } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import { privateRoute } from 'routes';
import { Inbox, Redeem, RocketLaunch, Storefront } from '@mui/icons-material';

const StyledListItemButton = styled(ListItemButton)({
  borderLeft: '4px solid transparent !important',
  '&.Mui-selected': {
    borderLeft: '4px solid var(--color-secondary-main) !important',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--color-secondary-main) !important',
  },
});

type MenuItemProps = {
  path: string;
  icon: any;
};

const MenuItem = ({ path, icon }: MenuItemProps) => {
  const location = useLocation();
  return (
    <>
      <Link to={path}>
        <StyledListItemButton selected={location.pathname.startsWith(path)}>
          <ListItemIcon>{icon}</ListItemIcon>
        </StyledListItemButton>
      </Link>
    </>
  );
};

const Menu = () => {
  const { home, merchant, network, transaction } = privateRoute;

  return (
    <>
      <List className='flex flex-col gap-1'>
        <MenuItem {...home} icon={<Inbox />} />
        <MenuItem {...merchant} icon={<Storefront />} />
        <MenuItem {...network} icon={<RocketLaunch />} />
        <MenuItem {...transaction} icon={<Redeem />} />
      </List>
    </>
  );
};

export default Menu;
