import { useState, useEffect } from 'react';
import { AppBar, Avatar, Box, CssBaseline, Drawer, IconButton, Toolbar } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import { Link, useLocation } from 'react-router-dom';
import { AppMenu } from 'containers';
import { Logout, Menu } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';

const drawerWidth = 60;

const Home = () => {
  const { isMobile } = useWindowDimensions();
  const location = useLocation();
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setOpenDrawer(false);
  }, [location.pathname]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
        color='secondary'
        elevation={0}
      >
        <Toolbar className='md:justify-end justify-between w-full bg-paper-gray'>
          <IconButton
            color='inherit'
            edge='start'
            onClick={() => setOpenDrawer(!openDrawer)}
            className='mr-2 block md:hidden'
          >
            <Menu />
          </IconButton>
          <Avatar src={require('assets/images/Image.Avatar.png')} />
          <IconButton className='md:ml-3' onClick={() => dispatch(signOut({}))}>
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component='nav' sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: 'var(--color-primary-main)',
            },
          }}
        >
          <Link to={privateRoute.home.path}>
            <div className='flex justify-center py-12'>
              <img src={require('assets/images/Image.Logo-White.png')} className='w-8' />
            </div>
          </Link>
          <AppMenu />
        </Drawer>
      </Box>
    </Box>
  );
};

export default Home;
