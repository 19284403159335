import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useNotification } from 'hooks';

export const appTheme = createTheme({
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        disableElevation: true,
      },
      styleOverrides: {
        sizeLarge: { minHeight: 48, minWidth: 48 },
        sizeMedium: { minHeight: 40, minWidth: 40 },
        sizeSmall: { minHeight: 32, minWidth: 32 },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        shape: 'rounded',
        size: 'large',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
        InputLabelProps: { shrink: true },
        InputProps: { disableUnderline: true },
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    button: { fontWeight: 600, textTransform: 'none' },
    subtitle1: { fontSize: 16, fontWeight: 500, lineHeight: 1.5 },
    subtitle2: { fontSize: 14, fontWeight: 500, lineHeight: 1.43 },
    body1: { fontSize: 16 },
    body2: { fontSize: 14 },
    caption: { fontSize: 12 },
  },
  palette: {
    text: {
      primary: '#111315',
    },
    primary: {
      light: '#1c6dda',
      main: '#1c6dda',
      dark: '#1c6dda',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
    },
    mode: 'light',
  },
  shape: {
    borderRadius: 6,
  },
});

const Theme = ({ children }: any) => {
  useNotification();
  return (
    <ThemeProvider theme={responsiveFontSizes(appTheme)}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>{children}</LocalizationProvider>
    </ThemeProvider>
  );
};

export default Theme;
