import { DateTime } from 'luxon';

export const formatBalance = (balance?: number) => {
  return (balance ?? 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const copy = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const isEmail = (text: string) => {
  return /^[\w.-]{1,}@[\w-]{2,}(.[\w-]{2,}){1,}$/.test(text);
};

export const shorten = (address: string, head = 5, tail = 4) => {
  return address ? address.slice(0, head) + '...' + address.slice(address.length - tail) : 'N/A';
};

export const getTimeFormat = (time: string) => {
  return time ? DateTime.fromISO(time).toFormat('MM/dd/yyyy HH:mm') : '';
};
