import { CloudQueue } from '@mui/icons-material';
import { Divider, Button, Dialog, Pagination } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { adminService } from 'services';
import { getTimeFormat, shorten } from 'utils/common';
import { TransactionStatus, TransactionType } from 'utils/enum';
import { PopupApprove, PopupReject } from '../popups';
import { TransactionRecordType } from 'models/Transaction';
import { Link } from 'react-router-dom';

const WithdrawTransactionList = ({ search }: { search: any }) => {
  const [dataSearch, onSearchChange] = useSearch({ ...search, page: 1, size: 10, type: TransactionType.WITHDRAWAL });
  const [openPopupApprove, setOpenPopupApprove] = useState(false);
  const [openPopupReject, setOpenPopupReject] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<TransactionRecordType>();

  const { data } = useQuery(['adminService.fetchTransactions', dataSearch], () =>
    adminService.fetchTransactions(dataSearch),
  );

  const { items = [], total, currentPage, pages } = data ?? {};

  return (
    <>
      <div className='flex items-center justify-between'>
        <div>
          <div className='text-xl font-semibold'>Withdraw request</div>
          <div className='text-[13px] text-[#6F767E]'>Total: {total}</div>
        </div>
        <Link to='/transaction'>
          <span className='text-[#2A85FF] font-bold'>View All</span>
        </Link>
      </div>
      {items.length > 0 ? (
        <>
          {items.map((item) => (
            <div className='flex items-center justify-between my-10 relative' key={item.id}>
              <div className='flex flex-col sm:flex-row items-start space-y-6 sm:space-x-6 sm:space-y-0'>
                <div>
                  <div className='text-[13px] text-[#6F767E]'>From</div>
                  <div className='text-sm font-semibold'>{shorten(item.from)}</div>
                </div>
                <Divider orientation='vertical' variant='middle' flexItem className='border hidden border-[#C7CBCF]' />
                <div>
                  <div className='text-[13px] text-[#6F767E]'>To</div>
                  <div className='text-sm font-semibold'>{shorten(item.to)}</div>
                </div>
              </div>
              <div className='text-[#E9422C] font-semibold text-sm'>OUT</div>
              <div className='font-semibold text-sm hidden sm:block'>{item.status}</div>
              <div className='flex items-end md:items-center flex-col sm:flex-row space-y-6 sm:space-x-6 sm:space-y-0'>
                <div className='text-right'>
                  <div className='text-[#535A64] text-[13px]'>{getTimeFormat(item.modifiedDate)}</div>
                  <div className='text-[#E9422C] font-semibold text-sm'>
                    -{item.amount} {item.token.symbol}
                  </div>
                </div>
                {item.status === TransactionStatus.PENDING && (
                  <>
                    <Button
                      variant='outlined'
                      size='small'
                      className='rounded-md px-4 py-2 text-xs'
                      onClick={() => {
                        setOpenPopupApprove(true);
                        setSelectedTransaction(item);
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant='outlined'
                      color='error'
                      size='small'
                      className='rounded-md px-4 py-2 text-xs'
                      onClick={() => {
                        setOpenPopupReject(true);
                        setSelectedTransaction(item);
                      }}
                    >
                      Reject
                    </Button>
                  </>
                )}
                <div className='block sm:hidden'>
                  <div className='text-[13px] text-[#6F767E]'>Status</div>
                  <div className='text-sm font-semibold'>{item.status}</div>
                </div>
              </div>
            </div>
          ))}
          <div className='flex items-center justify-center'>
            <Pagination
              page={currentPage ?? 1}
              count={pages}
              onChange={(event, value) => onSearchChange({ page: value })}
            />
          </div>
        </>
      ) : (
        <div className='text-center'>
          <CloudQueue fontSize='large' />
          <div className='text-sm font-medium'>No Data</div>
        </div>
      )}

      <Dialog open={openPopupApprove} fullWidth>
        <PopupApprove id={selectedTransaction?.id!} onClose={() => setOpenPopupApprove(false)} />
      </Dialog>

      <Dialog open={openPopupReject} fullWidth>
        <PopupReject id={selectedTransaction?.id!} onClose={() => setOpenPopupReject(false)} />
      </Dialog>
    </>
  );
};

export default WithdrawTransactionList;
