import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseButton } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { queryClient } from 'services';
import { adminService } from 'services';

const PopupAddNetwork = ({ onClose }: PopupController) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: addNetwork, isLoading } = useMutation(adminService.addNetwork, {
    onSuccess: () => {
      queryClient.invalidateQueries(['adminService.fetchListenerConfigs']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addNetwork({
        ...values,
        chainId: +values.chainId,
        delayBlock: +values.delayBlock,
        lastProcessedBlock: +values.lastProcessedBlock,
      } as CreateListenerConfigType);
    })();
  };

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Add Network</DialogTitle>

      <DialogContent className='mt-6'>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='chainName'
              defaultValue=''
              control={control}
              rules={{ required: 'Chain Name is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Chain Name</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='chainId'
              defaultValue=''
              control={control}
              rules={{ required: 'Chain Id is required' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Chain Id</label>
                  <TextField
                    {...field}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='delayBlock'
              defaultValue=''
              control={control}
              rules={{ required: 'Delay Block is required' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Delay Block</label>
                  <TextField
                    {...field}
                    type='number'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='lastProcessedBlock'
              defaultValue=''
              control={control}
              rules={{ required: 'Last Processed Block is required' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Last Processed Block</label>
                  <TextField
                    {...field}
                    type='number'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='httpUrl'
              defaultValue=''
              control={control}
              rules={{ required: 'Http Url is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>Http Url</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='wsUrl'
              defaultValue=''
              control={control}
              rules={{ required: 'WS Url is required' }}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl fullWidth>
                  <label className='text-[#6F767E] font-semibold text-sm mb-1'>WS Url</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='text' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' color='primary' loading={isLoading} onClick={handleClickSubmit}>
          Create
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupAddNetwork;
