import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { adminService } from 'services';

type PopupProps = PopupController & {
  id: string;
};

const PopupConfirm = ({ id, onClose }: PopupProps) => {
  const { mutate: resetPassword, isLoading } = useMutation(adminService.resetPassword, {
    onSuccess: () => {
      onClose();
    },
  });

  const handleConfirm = () => {
    resetPassword({ id });
  };

  return (
    <>
      <DialogTitle>CONFIRMATION</DialogTitle>

      <DialogContent>
        Are you want to <span className='font-bold text-red-700'>RESET PASSWORD</span> ?
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='text' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleConfirm}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupConfirm;
