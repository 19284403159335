import {
  Button,
  Chip,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { adminService } from 'services';
import { PopupAddNetwork, PopupDelete } from './popups';

const NetworkList = () => {
  const [dataSearch, onSearchChange] = useSearch({ orderBy: 'createdDate', page: 1, size: 10 });
  const [openAddNetwork, setOpenAddNetwork] = useState(false);
  const [openDeleteNetwork, setOpenDeleteNetwork] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<ListenerConfigRecordType>();

  const { data, isFetching } = useQuery(
    ['adminService.fetchListenerConfigs', dataSearch],
    () => adminService.fetchListenerConfigs(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages } = data ?? {};

  return (
    <>
      <Paper className='p-5'>
        <div className='flex items-center justify-between'>
          <div className='text-lg sm:text-xl font-semibold'>Network List</div>
          <Button variant='contained' onClick={() => setOpenAddNetwork(true)}>
            Add new chain
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Spinner loading={isFetching}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>NETWORK NAME</TableCell>
                  <TableCell>CURRENCY SYMBOL</TableCell>
                  <TableCell>CHAIN ID</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.chainName}</TableCell>
                    <TableCell>
                      <div className='flex gap-x-4'>
                        {item.tokens.map((item) => (
                          <Chip label={item.symbol} key={item.id} />
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>{item.chainId}</TableCell>
                    <TableCell align='center'>
                      <Button
                        className='whitespace-nowrap'
                        variant='outlined'
                        color='error'
                        size='small'
                        onClick={() => {
                          setOpenDeleteNetwork(true);
                          setSelectedNetwork(item);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRowEmpty visible={!isFetching && items.length === 0} />
              </TableBody>
              <caption>{total ?? 0} Networks</caption>
            </Table>
          </Spinner>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={currentPage ?? 1}
            count={pages}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </Paper>

      <Dialog open={openAddNetwork} fullWidth>
        <PopupAddNetwork onClose={() => setOpenAddNetwork(false)} />
      </Dialog>

      <Dialog open={openDeleteNetwork} fullWidth>
        <PopupDelete id={selectedNetwork?.id!} onClose={() => setOpenDeleteNetwork(false)} />
      </Dialog>
    </>
  );
};

export default NetworkList;
