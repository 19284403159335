import { RejectTransactionType, TransactionPaginateType, TransactionParamsType } from 'models/Transaction';
import { client } from './axios';

const getMerchantStatisticDashboard = (params?: DateParams): Promise<MerchantStatisticDashboardType> =>
  client.get(`/crypto-merchant-apis/merchant-statistic`, { params });

const getMerchantBalanceStatistic = (params?: DateParams): Promise<MerchantBalanceStatisticDashboardType> =>
  client.get(`/crypto-merchant-apis/merchant-statistic/merchant-balance`, { params });

const fetchMerchants = (params?: PaginateParams): Promise<MerchantPaginateType> =>
  client.get(`/crypto-merchant-apis/merchants`, { params });

const deactiveMerchant = ({ id }: { id: string }): Promise<string> =>
  client.put(`/crypto-account-apis/merchants/${id}/deactivate`);

const activeMerchant = ({ id }: { id: string }): Promise<string> =>
  client.put(`/crypto-account-apis/merchants/${id}/activate`);

const addMerchant = (body: AddMerchantType): Promise<MerchantRecordType> =>
  client.post(`/crypto-account-apis/merchants`, body);

const addNetwork = (body: CreateListenerConfigType): Promise<boolean> =>
  client.post(`/crypto-admin-apis/listener-config`, body);

const deleteNetwork = ({ id }: { id: string }): Promise<MerchantRecordType> =>
  client.delete(`/crypto-admin-apis/listener-config/${id}`);

const resetPassword = ({ id }: { id: string }): Promise<string> =>
  client.put(`/crypto-account-apis/merchants/${id}/reset-password`);

const fetchListenerConfigs = (params?: PaginateParams): Promise<ListenerConfigPaginateType> =>
  client.get(`/crypto-admin-apis/listener-config`, { params });

const approveTransaction = ({ id }: { id: string }): Promise<string> =>
  client.put(`/crypto-merchant-apis/merchant-transactions/${id}/approval`);

const rejectTransaction = ({ id, ...body }: RejectTransactionType): Promise<string> =>
  client.put(`/crypto-merchant-apis/merchant-transactions/${id}/rejection`, body);

const fetchTransactions = (params?: TransactionParamsType): Promise<TransactionPaginateType> =>
  client.get(`/crypto-merchant-apis/merchant-transactions`, { params });

const adminService = {
  fetchMerchants,
  deactiveMerchant,
  activeMerchant,
  addMerchant,
  fetchListenerConfigs,
  resetPassword,
  addNetwork,
  deleteNetwork,
  approveTransaction,
  rejectTransaction,
  getMerchantStatisticDashboard,
  getMerchantBalanceStatistic,
  fetchTransactions,
};
export default adminService;
