import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { TransactionRecordType } from 'models/Transaction';
import { useState } from 'react';
import { adminService } from 'services';
import { shorten } from 'utils/common';
import { TransactionStatus } from 'utils/enum';
import { PopupApprove, PopupReject } from 'views/Home/popups';

const TransactionList = () => {
  const [dataSearch, onSearchChange] = useSearch({ page: 1, size: 10 });
  const [openPopupApprove, setOpenPopupApprove] = useState(false);
  const [openPopupReject, setOpenPopupReject] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<TransactionRecordType>();

  const { data, isFetching } = useQuery(
    ['adminService.fetchTransactions', dataSearch],
    () => adminService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages } = data ?? {};

  return (
    <>
      <Paper className='p-5'>
        <div className='text-lg sm:text-xl font-semibold'>Transaction List</div>
        <TableContainer component={Paper}>
          <Spinner loading={isFetching}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>FROM</TableCell>
                  <TableCell>TO</TableCell>
                  <TableCell>TX HASH</TableCell>
                  <TableCell>AMOUNT USD</TableCell>
                  <TableCell>AMOUNT</TableCell>
                  <TableCell>AVAILABLE AMOUNT</TableCell>
                  <TableCell>REFCODE</TableCell>
                  <TableCell>TYPE</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>EXTRA REASON</TableCell>
                  <TableCell>TOKEN</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{shorten(item.from)}</TableCell>
                    <TableCell>{shorten(item.to)}</TableCell>
                    <TableCell>{shorten(item.txHash)}</TableCell>
                    <TableCell>{item.amountUSD}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.availableAmount}</TableCell>
                    <TableCell>{item.refCode}</TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.extraReason}</TableCell>
                    <TableCell>{item.token.symbol}</TableCell>
                    <TableCell align='center'>
                      {item.status === TransactionStatus.PENDING && (
                        <>
                          <Button
                            variant='outlined'
                            size='small'
                            className='rounded-md px-4 py-2 text-xs'
                            onClick={() => {
                              setOpenPopupApprove(true);
                              setSelectedTransaction(item);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant='outlined'
                            color='error'
                            size='small'
                            className='rounded-md px-4 py-2 text-xs'
                            onClick={() => {
                              setOpenPopupReject(true);
                              setSelectedTransaction(item);
                            }}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRowEmpty visible={!isFetching && items.length === 0} />
              </TableBody>
              <caption>{total ?? 0} Merchants</caption>
            </Table>
          </Spinner>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={currentPage ?? 1}
            count={pages}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </Paper>

      <Dialog open={openPopupApprove} fullWidth>
        <PopupApprove id={selectedTransaction?.id!} onClose={() => setOpenPopupApprove(false)} />
      </Dialog>

      <Dialog open={openPopupReject} fullWidth>
        <PopupReject id={selectedTransaction?.id!} onClose={() => setOpenPopupReject(false)} />
      </Dialog>
    </>
  );
};

export default TransactionList;
