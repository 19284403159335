import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { queryClient } from 'services';
import { adminService } from 'services';
import { PopupAddMerchant, PopupConfirm } from './popups';

const MerchantList = () => {
  const [dataSearch, onSearchChange] = useSearch({ page: 1, size: 10 });
  const [openAddMerchant, setOpenAddMerchant] = useState(false);
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
  const [selectMerchant, setSelectMerchant] = useState<MerchantRecordType>();

  const { data, isFetching } = useQuery(
    ['adminService.fetchMerchants', dataSearch],
    () => adminService.fetchMerchants(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages } = data ?? {};

  const { mutate: deactiveMerchant } = useMutation(adminService.deactiveMerchant, {
    onSuccess: () => {
      queryClient.invalidateQueries(['adminService.fetchMerchants']);
    },
  });

  const { mutate: activeMerchant } = useMutation(adminService.activeMerchant, {
    onSuccess: () => {
      queryClient.invalidateQueries(['adminService.fetchMerchants']);
    },
  });

  const handleSwitchChange = (item: MerchantRecordType) => {
    return item.active ? deactiveMerchant({ id: item.userId }) : activeMerchant({ id: item.userId });
  };

  return (
    <>
      <Paper className='p-5'>
        <div className='flex items-center justify-between'>
          <div className='text-lg sm:text-xl font-semibold'>Merchant List</div>
          <Button variant='contained' onClick={() => setOpenAddMerchant(true)}>
            Add Merchant
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Spinner loading={isFetching}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>DESCRIPTION</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>CODE</TableCell>
                  <TableCell>PHONE NUMBER</TableCell>
                  <TableCell>ADDRESS</TableCell>
                  <TableCell>SECRET KEY</TableCell>
                  <TableCell>API KEY</TableCell>
                  <TableCell>EMAIL</TableCell>
                  <TableCell>ACTIVE</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.url}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.phoneNumber}</TableCell>
                    <TableCell>{item.address}</TableCell>
                    <TableCell>{item.secretKey}</TableCell>
                    <TableCell>{item.apiKey}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      <Switch checked={item.active} onClick={() => handleSwitchChange(item)} />
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        className='whitespace-nowrap'
                        variant='outlined'
                        color='error'
                        size='small'
                        onClick={() => {
                          setOpenPopupConfirm(true);
                          setSelectMerchant(item);
                        }}
                      >
                        Reset Password
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRowEmpty visible={!isFetching && items.length === 0} />
              </TableBody>
              <caption>{total ?? 0} Merchants</caption>
            </Table>
          </Spinner>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={currentPage ?? 1}
            count={pages}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </Paper>

      <Dialog open={openAddMerchant} fullWidth>
        <PopupAddMerchant onClose={() => setOpenAddMerchant(false)} />
      </Dialog>

      <Dialog open={openPopupConfirm} fullWidth>
        <PopupConfirm id={selectMerchant?.userId!} onClose={() => setOpenPopupConfirm(false)} />
      </Dialog>
    </>
  );
};

export default MerchantList;
