import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { queryClient, adminService } from 'services';

type PopupProps = PopupController & {
  id: string;
};

const PopupApprove = ({ id, onClose }: PopupProps) => {
  const { mutate, isLoading } = useMutation(adminService.approveTransaction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['adminService.fetchTransactions']);
      onClose();
    },
  });

  const handleConfirm = () => {
    mutate({ id });
  };

  return (
    <>
      <DialogTitle>APPROVE</DialogTitle>

      <DialogContent>
        Are you want to <span className='font-bold text-green-700'>APPROVE</span> this transaction ?
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='text' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleConfirm}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupApprove;
